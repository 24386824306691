import { render, staticRenderFns } from "./ModuleForm.vue?vue&type=template&id=af11cfb2&scoped=true&"
import script from "./ModuleForm.vue?vue&type=script&lang=js&"
export * from "./ModuleForm.vue?vue&type=script&lang=js&"
import style0 from "./ModuleForm.vue?vue&type=style&index=0&id=af11cfb2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af11cfb2",
  null
  
)

export default component.exports